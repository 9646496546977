<template>
    <v-layout fill-height align-center justify-center>
        <v-overlay color="white" opacity="1" v-model="checkLoading">
            <v-progress-circular indeterminate color="secondary"/>
        </v-overlay>

        <v-card v-if="!error"
                class="elevation-0"
                width="600"
                style="padding: 20px; border-radius: 16px; border: 1px solid #eee">
            <v-card-title class="display-1 font-weight-black" style="word-break: break-word;">
                Почта изменена. Выполните вход
            </v-card-title>
            <v-card-actions>
                <v-btn @click="exit"
                       color="secondary"
                       style="width: 190px; height: 45px; border-radius: 8px;">Вход
                </v-btn>
            </v-card-actions>
        </v-card>

        <link-error-modal v-else/>
    </v-layout>
</template>

<script>
    import LinkErrorModal from "../components/LinkErrorModal";

    export default {
        components: {LinkErrorModal},
        name: "confirmation-view",
        data: () => ({
            loading: true,
            error: false,
            checkLoading: false
        }),
        methods: {
            exit() {
                this.$router.history.replace({name: 'login'})
            }
        },
        beforeMount() {
            const token = this.$route.query.token;
            if (token) {
                this.checkLoading = true;
                const params = {
                    headers: {token}
                };
                console.log(token)
                this.$http.post('/profile/email/update', {}, params)
                    .then(response => {
                        console.log('here')
                        this.data = response.data.data
                    }).catch(error => {
                        console.log(error)
                    this.error = true
                }).finally(() => {
                    setTimeout(() => {
                        this.checkLoading = false
                    }, 500)
                })
            } else this.error = true
        }
    }
</script>

<style scoped>

</style>